import React, {useState} from 'react'
// import Navbar from '../components/Navbar'
import ScrollToTop from '../components/ScrollToTop'
import Sidebar from '../components/SignSideBar'
import SignIn from '../components/Signin'
import Footer from '../components/Footer'
import {Helmet} from 'react-helmet';
import Navbar from '../components/SignNav'
const SigninPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () =>{
        setIsOpen(!isOpen);
    }

    return (
        <>  
            <Helmet>
                <title>Контакты</title>
                <meta
                    name="description"
                    content="Связаться с рестораном Ezoz | E'zoz | Эзоз" 
                />
            </Helmet>
            <ScrollToTop />
            <Sidebar isOpen = {isOpen} toggle = {toggle} />
            <Navbar toggle = {toggle} />
            <SignIn />
            <Footer/>
            
        </>
    )
}

export default SigninPage
