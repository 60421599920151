import React, { useState, useRef, useEffect } from "react";
import {
  ResInfoSection,
  ResInfoWrapper,
  ResSlide,
  ResSlider,
  ResImage,
  ResContent,
  SliderButtons,
  PrevArrow,
  NextArrow,
  Arrow,
} from "./ResInfoElements";
import { Button } from "../ButtonElements";
import { ArrowRight, ArrowForward } from "../HeroSection/HeroElements";
import { useSelector } from "react-redux";
const ResInfoTwo = ({ slides }) => {
  // console.log(slides);
  // let newslides = Array.from(slides);
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const timeout = useRef(null);
  const centralPicture = useSelector((state) => state.apiReducer.centralPicture);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };
    timeout.current = setTimeout(nextSlide, 3000);
    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  

  // central_picture
  return (
    <ResInfoSection>
      <ResInfoWrapper>
        {slides.map((slide, index) => {
          return (
            <ResSlide key={index}>
              {index === current && (
                <ResSlider>
                    {centralPicture[index] && (<ResImage src={centralPicture[index].central_picture} alt={slide.alt} />) }
                  <ResContent>
                    <h1>{slide.title}</h1>
                    <p>{slide.price}</p>
                    <Button
                      to="/signin"
                      primary="false"
                      dark="false"
                      smooth="true"
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      БРОНИРОВАТЬ
                    </Button>
                    {/* <Button to = {slide.path} primary = 'true'>{slide.label}<Arrow/></Button> */}
                  </ResContent>
                </ResSlider>
              )}
            </ResSlide>
          );
        })}
        ;
        <SliderButtons>
          <PrevArrow onClick={prevSlide} />
          <NextArrow onClick={nextSlide} />
        </SliderButtons>
      </ResInfoWrapper>
    </ResInfoSection>
  );
};

export default ResInfoTwo;
