import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRight,
  SocialIcons,
  SocialIconLink,
  FooterAddresWrap,
  MdLocation,
  BsFillTelephone,
  Facebook,
  Instagram,
  Mail,
} from "./FooterElements";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { animateScroll as scroll } from "react-scroll";
import { useSelector } from "react-redux";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const mainNumber = useSelector((state) => state.apiReducer.mainNumber);

  return (
    <FooterContainer id="footer">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>АДРЕС</FooterLinkTitle>
              <FooterAddresWrap>
                <MdLocation></MdLocation>
                <FooterLink to="/signin">
                  г.Ташкент, Чиланзарский район, Малая кольцевая дорога, 88
                  Б-дом{" "}
                </FooterLink>
              </FooterAddresWrap>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>КОНТАКТЫ</FooterLinkTitle>
              <FooterAddresWrap>
                <BsFillTelephone></BsFillTelephone>
                {!mainNumber ? (
                  ""
                ) : (
                  <FooterLink href={`tel:${mainNumber[0].phone}`}>
                    {mainNumber[0].phone}
                  </FooterLink>
                )}
              </FooterAddresWrap>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>SOCIAL</FooterLinkTitle>
              <FooterAddresWrap>
                <Instagram />
                <SocialIconLink
                  href="https://instagram.com/ezoz.restaurant?utm_medium=copy_link"
                  target="_blank"
                  aria-label="Instagram"
                >
                  ezoz.restaurant
                </SocialIconLink>
              </FooterAddresWrap>
              <FooterAddresWrap>
                <Facebook />
                <SocialIconLink
                  href="https://www.facebook.com/ezoz.resto"
                  target="_blank"
                  aria-label="Facebook"
                >
                  E`zoz Resto
                </SocialIconLink>
              </FooterAddresWrap>
              <FooterAddresWrap>
                <Mail />
                <SocialIconLink
                  href="mailto:ezoz.restaurant@bk.ru"
                  target="_blank"
                  aria-label="Email"
                >
                  ezoz.restaurant@bk.ru
                </SocialIconLink>
              </FooterAddresWrap>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Ezoz
            </SocialLogo>
            <WebsiteRight>
              Ezoz © {new Date().getFullYear()} All rights reserved
            </WebsiteRight>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/ezoz.resto"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://instagram.com/ezoz.restaurant?utm_medium=copy_link"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="mailto:ezoz.restaurant@bk.ru"
                target="_blank"
                aria-label="Email"
              >
                <MdEmail />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
