import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import apiReducer from "../redux/reducers/apiReducer";
const rootReducer = combineReducers({
  apiReducer,
});

export const setUpStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};
