import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 100px;
    padding: 0 40px;
    // padding-top: 130px;
    // min-height: 692px;
    // max-width: 975px;
    // margin: 0 auto;
    // position: realtive;
    // overflow-x: hidden
    // bottom: 0;
    // left: 0;
    // right: 0;
    // top: 0;
    // z-index: 0;
    overflow: hidden;
    // background: #0B1315;

   
    // @media screen and (max-width: 992px){
    //     padding: 30px;
    // }
    // @media screen and (max-width: 576px){
    //     padding-top: 112px;
    // }
    // @media screen and (max-width: 400px){
    //     padding-top: 140px;
    // }
`

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 29px;
    overflow: hidden;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
    }
    // @media screen and (min-width: 1000px){
    //     grid-template-columns: 1fr 1fr 1fr;
    // }
`

export const GalleryImage = styled.div`
    text-align: center;
    cursor: pointer;
`