import styled from 'styled-components'
import {Link} from 'react-router-dom'
import img from '../../images/svg_1.svg'


export const Container = styled.div`
    padding-top: 130px;
    min-height: 692px;
    max-width: 975px;
    margin: 0 auto;
    position: realtive;
    overflow-x: hidden
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: #0B1315;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 20 20px;
    }
    @media screen and (max-width: 992px){
        padding: 30 30px;
        grid-gap: 0;
    }
    @media screen and (max-width: 576px){
        padding-top: 112px;
    }
    @media screen and (max-width: 400px){
        padding-top: 140px;
    }

`

export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 400px){
        height: 80%;
    }
`

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top: 8px;
    }
`

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px){
        padding: 10px;
    }
`

export const Form = styled.form`
    // background: #010101;
    max-width: 400px;
    height: auto;
    width: 100%;
    // z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 80px 32px;
    
    // border-radius: 4px;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.9);

    @media screen and (max-width: 400px){
        padding: 32px 32px;
    }
`

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: #fff;
`

export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 28px;
    border: 1px solid #715B3E;
    box-sizing: border-box;
    border-radius: 0px;
    background: transparent;
    color: #fff;
    &:last-child{
    height: 100px;
    }
`

export const StyledTextarea = styled.textarea`
    font-family: Oswald;
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 1px solid #715B3E;
    box-sizing: border-box;
    border-radius: 0px;
    background: transparent;
    color: #fff;
`

export const FormButton = styled.button`
    cursor: pointer;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 5.04px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: transparent;
    width: 136px;
    height: 47px;
    border: 1px solid #FF871C;
    box-sizing: border-box;
    border-radius: 0px;
    margin-left: auto;
        
`

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
`





export const LocationContainer = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 90px;
    margin-bottom: 85px;
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
    @media screen and (max-width: 440px){
        margin-bottom: 50px;
    }
    margin-top: 120px;
    max-width: 1170px;
`
export const LocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const LocationP = styled.p`
    font-family: Miniver;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.76px;
    color: #FF871C;
    margin-bottom: 18px;
    @media screen and (max-width: 440px){
        font-size: 14px;
    }
`

export const LocationH1 = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 9.43px;
    text-transform: uppercase;
    color: #FF871C;
    position: relative;
    text-align: center;
    
    &:before{
        content: '';
        width: 41px;
        height: 9px;
        background-image: url(${img});
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        left: -18%;
        top: 16px;
    }
    &:after{
        content: '';
        width: 41px;
        height: 9px;
        background-image: url(${img});
        position: absolute;
        bottom: 0;
        right: -18%;
        top: 16px;
    }
    @media screen and (max-width: 440px){
        font-size: 18px;
    }
    @media screen and (max-width: 992px){
        font-size: 18px;
        letter-spacing: 5.43px;
    }
`

// export const FormInput = styled.input`
//     padding: 16px 16px;
//     margin-bottom: 32px;
//     border: 1px solid #715B3E;
//     box-sizing: border-box;
//     border-radius: 0px;
//     background: transparent;
// `

export const SignWrap = styled.div`
    display: flex;
    flex-direction: column;
`

export const LocWarp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const LocH1 = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 4.37px;
    text-transform: uppercase;
    color: #FF871C;
    margin-bottom: 12px;
`

export const LocP = styled.p`
    text-align: center;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: #FFFFFF;
`

export const LocDescWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`

export const LocDescH1 = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 61px;
    letter-spacing: 10.56px;
    text-transform: uppercase;
    color: #FF871C;
    margin-bottom: 24px;
    @media screen and (max-width: 992px){
        font-size: 19px;
    }
`

export const LocDescList = styled.div`
    display: flex;
    flex-direction: column;
`

export const LocItemP = styled.p`
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 29px;
    color: #FFFFFF;
    margin-bottom: 4px;

`