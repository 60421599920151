import React, { useEffect } from "react";
import { VideoBg, VideoContainer, VideoSection } from "./VideoElements";
import Video1 from "../../videos/video_4.mp4";
import "swiper/swiper.min.css";
import Aos from "aos";
import { useSelector } from "react-redux";
const Video = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const video = useSelector((state) => state.apiReducer.video);     

  return (
    <VideoSection id={"about"}>
      <VideoContainer data-aos="fade-up-left">
        {!video ? (
          " "
        ) : (
          <VideoBg controls src={video[0].video} type="video/mp4"></VideoBg>
        )}
      </VideoContainer>
    </VideoSection>
  );
};

export default Video;
