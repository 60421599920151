import axios from "axios";

export const API_URL = "https://ezozrestaurant.uz/";

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const api = {
  background() {
    return instance.get("https://api.ezozrestaurant.uz/api/background").then((data) => {
      return data;
    });
  },
  video() {
    return instance.get("https://api.ezozrestaurant.uz/api/video").then((data) => {
      return data;
    });
  },
  firstSlider() {
    return instance.get("https://api.ezozrestaurant.uz/api/first_slider").then((data) => {
      return data;
    });
  },
  secondSlider() {
    return instance.get("https://api.ezozrestaurant.uz/api/second_slider").then((data) => {
      return data;
    });
  },
  centralPicture() {
    return instance.get("https://api.ezozrestaurant.uz/api/central_picture").then((data) => {
      return data;
    });
  },
  gallery() {
    return instance.get("https://api.ezozrestaurant.uz/api/gallery").then((data) => {
      console.log(data)
      return data;
    });
  },
  firstComment() {
    return instance.get("https://api.ezozrestaurant.uz/api/first_comment").then((data) => {
      return data;
    });
  },
  secondComment() {
    return instance.get("https://api.ezozrestaurant.uz/api/second_comment").then((data) => {
      return data;
    });
  },
  request(req) {
    return instance.post("https://api.ezozrestaurant.uz/request/list/", req).then((data) => {
      return data;
    });
  },
  mainNumber() {
    return instance.get("https://api.ezozrestaurant.uz/api/main_number").then((data) => {
      return data;
    });
  },
  number() {
    return instance.get("https://api.ezozrestaurant.uz/api/number").then((data) => {
      return data;
    });
  },
};
