import React from 'react'
// import image3 from '../../images/svg_2.svg'
import {SocialIcons, SocialLabel, Btn, IconListWrap, IconList, IconLink, Button_1, IconsL, Icons, Facebook, Instagram, Telephone, Mail, Image1 } from './StickyButtonElements'
import {FaFacebook, FaInstagram, FaLinkedin, FaShareAlt, FaTwitter, FaYoutube} from 'react-icons/fa'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {MdEmail, MdLocationPin} from 'react-icons/md'
import image4 from '../../images/svg_2.svg'

const StickyButton = () => {
    // const content = document.getElementById("content");
    // const btn = document.getElementById("show");
    // function clickme(){
    //     if(content.className == "open"){
    //         content.className = "";
    //         console.log('pressed');
    //     } else{
    //         content.className = "open";
    //         console.log('pressed twice');
    //     }
    // };

    //var share_btn = document.querySelector(".share_btn");
    const share_btn= React.useRef();
	//var toggle_button = document.querySelector(".toggle_button");
    const toggle_btn = React.useRef()
    // if(share_btn){
    //     share_btn.addEventListener("click", function(){
    //         toggle_button.classList.toggle();
    //     })
    // }
    React.useEffect(() => {
        share_btn.current.addEventListener('click', function(){
            toggle_btn.current.classList.toggle("active");
        })
    }, [])
	
    return (
        

          
            <IconListWrap>
                <IconList ref = {toggle_btn} className = 'toggle_button shake'>
                    <Icons>
                        <IconsL ref = {share_btn} className = 'share_btn'>
                            <IconLink><Image1 src = {image4} alt = 'contact me'></Image1></IconLink>
                        </IconsL>
                        <IconsL>
                            <IconLink className = "sm" href = 'https://instagram.com/ezoz.restaurant?utm_medium=copy_link' target = "_blank" aria-label = "Instagram"><Instagram /></IconLink>
                        </IconsL>
                        <IconsL>
                            <IconLink className = "sm" href = 'https://www.facebook.com/ezoz.resto' target = "_blank" aria-label = "Facebook"><Facebook /></IconLink>
                        </IconsL>
                        <IconsL>
                            <IconLink className = "sm" href = 'mailto:ezoz.restaurant@bk.ru' target = "_blank" aria-label = "Email"><Mail /></IconLink>
                        </IconsL>
                        <IconsL>
                            <IconLink className = "sm" href = 'tel:951779933' target = "_blank" aria-label = "Telephone"><Telephone/></IconLink>
                        </IconsL>

                    </Icons>
                    
                    

                </IconList>
                {/* <Button_1 onclick = {clickme} id = 'show'><FaFacebook/></Button_1> */}
            </IconListWrap>

            // <div class="wrapper">
            //     <div ref={toggle_btn} class="toggle_button">
            //         <ul>
            //             <li>
            //                 <a ref={share_btn} class="share_btn">
            //                     {/* <span class="text">SHARE</span> */}
            //                     <span class="icon"><img src = {image4} alt = 'contact me'/></span>
            //                 </a>
            //             </li>
            //             <li>
            //                 <a href="#" class="sm facebook">
            //                     <span class="icon">
            //                         <FaInstagram/>
            //                     </span>
            //                     {/* <span class="text">Facebook</span> */}
            //                 </a>
            //             </li>
            //             <li>
            //                 <a href="#" class="sm twitter">
            //                     <span class="icon">
            //                         <MdEmail/>
            //                     </span>
            //                     {/* <span class="text">Twitter</span> */}
            //                 </a>
            //             </li>
            //             <li>
            //                 <a href="#" class="sm reddit">
            //                     <span class="icon">
            //                         <BsFillTelephoneFill/>
            //                     </span>
            //                     {/* <span class="text">Reddit</span> */}
            //                 </a>
            //             </li>
            //         </ul>
            //     </div>
            // </div>



   
    
    )
}

export default StickyButton
