import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {MdLocationPin, MdEmail} from 'react-icons/md'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {FaFacebook, FaInstagram} from 'react-icons/fa'

export const FooterContainer = styled.div`
    background: #0B1315;
`

export const FooterWrap = styled.div`
    padding: 100px 24px 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 820px){
        padding-top: 32px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 576px){
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        font-size: 15px;
    }
    @media screen and (max-width: 440px){
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        font-size: 15px;
    }
`

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
    @media screen and (max-width: 440px){
        align-items: center;
    }
`

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`

export const FooterLinkTitle = styled.h1`
    font-family: Open Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #E37816;
    margin-bottom: 30px;
`

export const FooterLink = styled.a`
    // color: #fff;
    // margin-bottom: 0.5rem;
    // font-size: 14px;
    text-decoration: none;
    font-family: Open Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #E37816;
    
    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`
export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`

export const SocialLogo = styled(Link)`
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    // display: flex;
    // align-items: center;
    // margin-bottom: 16px;
    // font-weight: bold;

    font-family: "Metropolis 1920";
    font-style: normal;
    font-weight: normal;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #E37816;
`

export const WebsiteRight = styled.small`
    color: #E37816;
`

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`

export const SocialIconLink = styled.a`
    text-decoration: none;
    font-family: Open Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #E37816;

    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`

export const FooterAddresWrap = styled.div`
    display: flex;
    margin-bottom: 30px;
`

export const MdLocation = styled(MdLocationPin)`
    height: 17%;
    width: 100%;
    color: #E37816;
    margin-right: 6px;
    @media screen and (max-width: 400px){
        height: 39%;
        width: 45px;
    }
`

export const BsFillTelephone = styled(BsFillTelephoneFill)`
    height: 19px;
    width: 25px;
    color: #E37816;
    margin-right: 6px;
`
export const Mail = styled(MdEmail)`
    height: 24px;
    width: 25px;
    color: #E37816;
    margin-right: 6px;
 `

export const Facebook = styled(FaFacebook)`
    height: 24px;
    width: 25px;
    color: #E37816;
    margin-right: 6px;
`

export const Instagram = styled(FaInstagram)`
    height: 24px;
    width: 25px;
    color: #E37816;
    margin-right: 6px;
`