import React, { useEffect, useState } from "react";
import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  LocationWrapper,
  LocationP,
  LocationH1,
  SignWrap,
  LocWarp,
  LocH1,
  LocP,
  LocDescWrap,
  LocDescH1,
  LocDescList,
  LocItemP,
  StyledTextarea,
} from "./SigninElements";
import emailjs from "emailjs-com";
import "swiper/swiper.min.css";
import Aos from "aos";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { number, request } from "../../redux/reducers/apiReducer";

const SignIn = () => {
  // function sendEmail(e) {
  //     emailjs.sendForm('service_ih2tp7m', 'template_kdce8yx', e.target, 'user_yQNWL98z5aQYbO71XAsD5').then(res => {
  //         console.log(res);
  //     }).catch(err => console.log(err));
  // };
  // React.useEffect(() =>{
  //     const form1 = document.querySelector('#sign_form');
  //     form1.addEventListener('submit', (e) => {
  //     e.preventDefault();

  //     let form = this,
  //     submit = $('.submit', form),
  //     data = new FormData(),
  //     files = $('input[type=file]')

  //     $('.submit', form).val('Отправка...');
  //     $('input, textarea', form).attr('disabled','');

  //     data.append( 'name', 		$('[name="name"]', form).val() );
  //     data.append( 'phone', 		$('[name="phone"]', form).val() );
  //     data.append( 'message', 		$('[name="message"]', form).val() );

  //     files.each(function (key, file) {
  //         let cont = file.files;
  //         if ( cont ) {
  //             $.each( cont, function( key, value ) {
  //                 data.append( key, value );
  //             });
  //         }
  //     });

  //     $.ajax({
  //     url: 'ajax.php',
  //     type: 'POST',
  //     data: data,
  //     cache: false,
  //     dataType: 'json',
  //     processData: false,
  //     contentType: false,
  //     xhr: function() {
  //         let myXhr = $.ajaxSettings.xhr();

  //         if ( myXhr.upload ) {
  //             myXhr.upload.addEventListener( 'progress', function(e) {
  //                 if ( e.lengthComputable ) {
  //                     let percentage = ( e.loaded / e.total ) * 100;
  //                         percentage = percentage.toFixed(0);
  //                     $('.submit', form)
  //                         .html( percentage + '%' );
  //                 }
  //             }, false );
  //         }

  //         return myXhr;
  //     },
  //     error: function( jqXHR, textStatus ) {
  //         // Тут выводим ошибку
  //         console.log('Error');
  //     },
  //     complete: function() {
  //         // Тут можем что-то делать ПОСЛЕ успешной отправки формы
  //         console.log('Complete');

  //     }
  //     });

  //     return false;

  // })

  // })

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  let dispatch = useDispatch();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  let sendForm = (e) => {
    e.preventDefault();
    dispatch(
      request({
        full_name: name,
        phone,
        comment: message,
      })
    )
        .then(() => {
            setName("")
            setPhone("")
            setMessage("")
        });
  };
  const number = useSelector((state) => state.apiReducer.number);

  // afterSubmission(event) {
  //     event.preventDefault();
  //     let name = this.state.itemName;
  //     this.setState ({
  //         storedItemName:this.state.itemName
  //     }, function() {
  //         alert(this.state.storedItemName); // Shows the right value!
  //     }
  // }
  return (
    <>
      {/* <Helmet>
            <meta
                name="description"
                content="Связаться с рестораном Ezoz | E'zoz | Эзоз" 
            />
            <title>Контакты</title>
        </Helmet> */}
      <Container>
        <FormWrap data-aos="fade-right">
          <LocationWrapper>
            <LocationP>E’zoz</LocationP>
            <LocationH1>СВЯЖИТЕСЬ С НАМИ</LocationH1>
          </LocationWrapper>
          <FormContent>
            <Form id="sign_form">
              {/* <FormLabel  htmlFor = 'for'>Имя</FormLabel> */}
              <FormInput
                placeholder="Имя"
                onChange={(e) => setName(e.target.value)}
                value={name}
                name="name"
                type="text"
                required
              />
              {/* <FormLabel  htmlFor = 'for'>Телефон</FormLabel> */}
              <FormInput
                placeholder="Телефон"
                name="phone"
                id="phone"
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
              />
              {/* <FormLabel htmlFor = 'for'>Сообщение</FormLabel> */}
              <StyledTextarea
                placeholder="Сообщение"
                name="message"
                type="text"
                rows="5"
                required
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <FormButton onClick={sendForm} value="Send" type="submit">
                Отправить
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
        <SignWrap data-aos="fade-up">
          <LocWarp>
            <LocH1>ТАШКЕНТ</LocH1>
            <LocP>Чиланзарский район, Малая кольцевая дорога, 88 Б-дом</LocP>
            {!number
              ? ""
              : number.map((el) => {
                  return <LocP>{el.phone}</LocP>;
                })}
            <LocP>ezoz.restaurant@bk.ru</LocP>
          </LocWarp>
          <LocDescWrap>
            <LocDescH1>ВРЕМЯ РАБОТЫ</LocDescH1>
            <LocDescList>
              <LocItemP>Понедельник: 10:00 – 22:00</LocItemP>
              <LocItemP>Втроник: 10:00 – 22:00</LocItemP>
              <LocItemP>Среда: 10:00 – 22:00</LocItemP>
              <LocItemP>Четверг: 10:00 – 22:00</LocItemP>
              <LocItemP>Пятница: 10:00 – 22:00</LocItemP>
              <LocItemP>Суббота: 10:00 – 22:00</LocItemP>
              <LocItemP>Воскресенье: 10:00 – 22:00</LocItemP>
            </LocDescList>
          </LocDescWrap>
        </SignWrap>
      </Container>
    </>
  );
};

export default SignIn;
