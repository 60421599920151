import React, { useEffect, useState } from "react";
import Gallery from "react-grid-gallery";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Container, Wrapper, GalleryImage } from "./GalleryElements";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import slider_img_1 from "../../images/image_3.png";
import slider_img_2 from "../../images/image_4.png";
import slider_img_3 from "../../images/image_5.png";
import slider_img_4 from "../../images/image_9.png";
import slider_img_5 from "../../images/image_11.png";
import slider_img_6 from "../../images/image_8.png";
import slider_img_7 from "../../images/image_6.png";
import slider_img_8 from "../../images/image_10.png";
import slider_img_9 from "../../images/image_11.png";
import slider_img_10 from "../../images/image_12.png";
import slider_img_11 from "../../images/image_13.png";
import slider_img_12 from "../../images/image_14.png";
import slider_img_13 from "../../images/image_15.png";
import slider_img_14 from "../../images/image_23.png";
import slider_img_15 from "../../images/image_24.png";
import { useSelector } from "react-redux";
// import slider_img_14 from '../../images/image_17.png'
// import slider_img_15 from '../../images/image_18.png'

const Gallery1 = () => {
  const gallery = useSelector((state) => state.apiReducer.gallery);

  return (
    <Container>
      <SimpleReactLightbox>
        <SRLWrapper>
          <Wrapper>
            {Array.isArray(gallery) && gallery.map((image) => {
                  return (
                    <GalleryImage id={"GalleryImage-" + image.id}>
                      <img
                        style={{ height: "300px" }}
                        src={image.pictures}
                        alt="Logo"
                      />
                    </GalleryImage>
                  );
                })}
            {/* <GalleryImage><img src={slider_img_1} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_2} alt="Logo" /></GalleryImage>
                        <GalleryImage><img  src={slider_img_3} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_4} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_5} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_6} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_7} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_8} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_9} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_10} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_11} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_12} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_13} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_14} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_15} alt="Logo" /></GalleryImage> */}
            {/* <GalleryImage><img src={slider_img_14} alt="Logo" /></GalleryImage>
                        <GalleryImage><img src={slider_img_15} alt="Logo" /></GalleryImage> */}
          </Wrapper>
        </SRLWrapper>
      </SimpleReactLightbox>
    </Container>
  );
};

// const TagButton = () => {
//     return <button>ALL</button>
// }

export default Gallery1;
