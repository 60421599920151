import React, { useState, useEffect } from "react";
import Video from "../../videos/video.mp4";
import Image from "../../images/image_1.png";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  HeroSpan,
  HeroSpanOne,
  HeroDown,
  ImageBg,
  ArrowWrap,
  ArrowSpan,
} from "./HeroElements";
import { Button } from "../ButtonElements";
import { VscFoldDown } from "react-icons/vsc";
import "swiper/swiper.min.css";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  const videoBack = useSelector((state) => state.apiReducer.videoBack);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {/* <Helmet>
            <meta
                name="description"
                content="Добро пожаловать в ресторан Ezoz | Эзоз | E'zoz " 
            /> 
            <title>Ezoz restaurant</title>
        </Helmet> */}
      <HeroContainer id="home">
        <HeroBg>
          {Array.isArray(videoBack) && videoBack.map((el) => {
                return (
                  <VideoBg
                    autoPlay
                    loop
                    muted
                    src={el.video}
                    type="video/mp4"
                  ></VideoBg>
                );
              })}
          {/* <ImageBg src ={Image}></ImageBg> */}
        </HeroBg>
        <HeroContent>
          <HeroH1 data-aos="fade-right">Добро пожаловать в </HeroH1>
          <HeroSpanOne>RESTORAN</HeroSpanOne>
          <HeroSpan data-aos="fade-up">E’ZOZ</HeroSpan>
          <HeroP data-aos="fade-left">Сделайте заказ по ссылке ниже</HeroP>
          {/* <HeroDown>
                    <VscFoldDown/>
                </HeroDown> */}
          <ArrowWrap>
            {/* <ArrowSpan></ArrowSpan> */}
            <ArrowSpan></ArrowSpan>
            <ArrowSpan></ArrowSpan>
          </ArrowWrap>
          <HeroBtnWrapper>
            <Button
              to="/signin"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="false"
              dark="false"
              smooth="true"
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              БРОНИРОВАТЬ {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
