import React, { useState, useEffect } from "react";
import {
  SliderData,
  SliderContainer,
  InfoWrapper,
  InfoH1,
  InfoP,
  InfoH2,
  MainWrapper,
  MainContainer,
} from "./ResInfoElements";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import slider_img from "../../images/menu/image_2.png";
import slider_img_1 from "../../images/menu/image_3.png";
import slider_img_2 from "../../images/menu/image_4.png";
import slider_img_3 from "../../images/menu/image_5.png";
import slider_img_4 from "../../images/menu/image_9.png";
import slider_img_5 from "../../images/menu/image_11.png";
import slider_img_6 from "../../images/menu/image_8.png";
import slider_img_7 from "../../images/menu/image_6.png";
import slider_img_8 from "../../images/menu/image_10.png";
import slider_img_9 from "../../images/menu/image_11.png";
import slider_img_10 from "../../images/menu/image_12.png";
import slider_img_11 from "../../images/menu/image_13.png";
import slider_img_12 from "../../images/menu/image_14.png";
import slider_img_13 from "../../images/menu/image_15.png";
import "swiper/swiper.min.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

const ResInfoSection = ({ slides, img }) => {
  // const [current, setCurrent] = useState(0);
  // const length = slides.length;
  // const nextSlide = () =>{
  //     setCurrent(current === length -1 ? 0 : current + 1);
  // };
  // const prevSlide = () =>{
  //     setCurrent(current === 0 ? length -1 : current - 1);
  // };
  // console.log(current);
  // if(!Array.isArray(slides) || slides.length <= 0){
  //     return null;
  // };
  SwiperCore.use([Autoplay]);
  const second_slider = useSelector((state) => state.apiReducer.second_slider);
  const second_comment = useSelector(
    (state) => state.apiReducer.second_comment
  );

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {/* <section className = "slider">
                <FaArrowCircleLeft className = "left-arrow" onClick = {prevSlide} />
                <FaArrowCircleRight className = "right-arrow" onClick = {nextSlide}/>
                {SliderData.map((slide, index) => {
                    return (
                        <div className = {index = current ? 'slide active' : 'slide'} key = {index}>
                            {index === current && (<img src = {slide.img} alt = 'travel' className = 'image'/>)}
                        </div>
                    )
                })}
            </section> */}
      <MainContainer id={"notabout"}>
        <MainWrapper>
          <InfoWrapper>
            {!second_comment ? (
              ""
            ) : (
              <>
                <InfoH1>{second_comment[0].thik_font}</InfoH1>
                <InfoP>{second_comment[0].thin_font}</InfoP>
              </>
            )}

            <InfoH2
              to="footer"
              smooth="true"
              duration={500}
              spy={true}
              exact="true"
            >
              СВЯЖИТЕСЬ С НАМИ
            </InfoH2>
          </InfoWrapper>
          <SliderContainer>
            <Swiper
              // spaceBetween={40}
              // slidesPerView={2}
              loop={true}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                // 576: {
                //     width: 576,
                //     slidesPerView: 1,
                //     spaceBetween: 10
                // },
                // when window width is >= 640px
                300: {
                  width: 310,
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                640: {
                  width: 320,
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                // when window width is >= 768px
                768: {
                  width: 670,
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  width: 640,
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {Array.isArray(second_slider) && second_slider.map((el) => {
                    return (
                      <SwiperSlide>
                        <img
                          style={{ height: "448px", width: '320px' }}
                          src={el.pictures}
                          alt="Logo"
                        />
                      </SwiperSlide>
                    );
                  })}
              {/* <SwiperSlide>
                {" "}
                <img src={slider_img} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_1} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_2} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_3} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_4} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_5} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_6} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_7} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_8} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_9} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_10} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_11} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_12} alt="Logo" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={slider_img_13} alt="Logo" />
              </SwiperSlide> */}
            </Swiper>
          </SliderContainer>
        </MainWrapper>
      </MainContainer>
    </>
  );
};

export default ResInfoSection;
