import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
    height: 100px;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;
export const NavLogo = styled(LinkR)`
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    margin-top: 4px;

    font-family: "Metropolis 1920"; 
    font-style: normal;
    font-weight: normal;
    font-size: 43px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #E37816;
`;

export const NavSpan = styled.span`
    font-family: "Metropolis 1920"; 
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #E37816;
    margin-top: -30px;
    margin-left: -6px;
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 760px){
        display: block;
        position: absolute;
        top: 13px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
    margin: 0 40px;

    @media screen and (max-width: 1000px){
        margin: 0 13px;
    }
`;

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-family: 'Oswald', sans-serif;

    &.active {
        border-bottom: 3px solid #01bf71;
    }

`;

export const NavBtn = styled.li`
    display: flex;
    align-items: center;
    margin: 0 50px;
    font-family: 'Oswald', sans-serif;

    @media screen and (max-width: 1000px){
        margin: 0 13px;
    }

    @media screen and (max-width: 768px){
        display: none;
    }
    
`

export const NavBtnLink = styled(LinkR)`
    // border-radius: 50px;
    // background: #01bf71;
    // white-space: nowrap;
    // padding: 10px 22px;
    // color: #010606;
    // font-size: 16px;
    // outline: none;
    // border: none;
    // cursor: pointer;
    // transition: all 0.2s ease-in-out;
    // text-decoration: none;

    // &:hover{
    //     transition: all 0.2s ease-in-out;
    //     background: #fff;
    //     color: #010606;
    // }

    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #01bf71;
    }
`