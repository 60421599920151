import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import Gallery from "./pages/galley";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  centralPicture,
  firstComment,
  firstSlider,
  gallery,
  mainNumber,
  number,
  secondComment,
  secondSlid,
  video,
  videoBg,
} from "./redux/reducers/apiReducer";
function App() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(videoBg());
    dispatch(video());
    dispatch(firstSlider());
    dispatch(centralPicture());
    dispatch(secondSlid());
    dispatch(gallery());
    dispatch(firstComment());
    dispatch(secondComment());
    dispatch(mainNumber());
    dispatch(number());
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/signin" element={<SigninPage />} exact />
        <Route path="/gallery" element={<Gallery />} exact />
      </Routes>
    </Router>
  );
}

export default App;
