import styled, {keyframes} from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'
import {VscFoldDown} from 'react-icons/vsc'

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;
    overflow-x: hidden;

    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34; 
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    padding: 8px 24px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.h1`
    color: #fff;
    // font-size: 48px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 20px;
    letter-spacing: 0.1em;
    margin-bottom: 83px;
    margin-top: 90px;

    @media screen and (max-width: 768px){
        font-size: 22px;
    }
    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    font-family: 'Montserrat', sans-serif;
    

    @media screen and (max-width: 768px){
        font-size: 24px;
    }
    @media screen and (max-width: 768px){
        font-size: 18px;
    }
`
export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const HeroSpanOne = styled.span`
    font-family: "Metropolis 1920";
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #E37816;
    margin-bottom: 20px;
`

export const HeroSpan = styled.span`
    font-family: "Metropolis 1920";
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #E37816;
    margin-bottom: 20px;
`

export const HeroDown = styled(VscFoldDown)`
    display: flex;
    height: 60px;
    width: 100%;
    margin-top: 20px;

    color: #E37816;

`

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

export const ArrowWrap = styled.div`
    margin: 11px;
`

const animate = keyframes`
     0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
`

export const ArrowSpan = styled.span`
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #06A8FF;
    border-right: 5px solid #06A8FF;
    transform: rotate(45deg);
    margin: -10px;
    animation: ${animate} 2s infinite;
    color: #E37816;
    border-bottom: 6px solid #E37816;
    border-right: 6px solid #E37816;
    &:nth-child(2){
        animation-delay: -0.2s;
    }
    &:nth-child(3){
        animation-delay: -0.4s;
    }
`

