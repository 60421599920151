import React, {useEffect} from 'react'
import {LocationContainer, LocationWrapper, LocationP, LocationH1, LocationFrame, FrameWrap} from './LocationElements'
import { Map, GoogleApiWrapper } from 'google-maps-react'
import 'swiper/swiper.min.css'
import Aos from 'aos'
const Location = () => {
    useEffect(() => {
        Aos.init({duration: 2000});

    }, []);
    // const mapStyles = {
    //     width = '600', height = '450', border = '0', allowfullscreen="", loading="lazy"
    //   };
    let _url = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11994.575465528767!2d69.2079341!3d41.2730895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1159042abea70e7!2z0K3Qt9C-0Lc!5e0!3m2!1sru!2s!4v1637441193128!5m2!1sru!2s";
    // <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11994.575465528767!2d69.2079341!3d41.2730895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1159042abea70e7!2z0K3Qt9C-0Lc!5e0!3m2!1sru!2s!4v1637441193128!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    return (
        <>
            <LocationContainer  id = {'location'}>
                <LocationWrapper data-aos = "fade-down">
                    <LocationP>E’ZOZ RESTAURANT</LocationP>
                    <LocationH1>НАШАЛОКАЦИЯ</LocationH1>
                </LocationWrapper>
                <FrameWrap data-aos = "fade-up">
                    <LocationFrame src = {_url} frameBorder="0"  style={{ width: "100%", height: "784"}} allowfullscreen = "" overflow = 'scroll' loading = "lazy"></LocationFrame>
                </FrameWrap>
            </LocationContainer>
            
        </>
    )

}
export default Location 
