import styled from 'styled-components'

export const VideoSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    position: relative;
    margin-bottom: 40px;
    overflow-x: hidden;
`
export const VideoContainer = styled.div`
    max-width: 1140px;
    max-height: 680px; 
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`
