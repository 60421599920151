import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, NavSpan} from './NavbarElements';
import {IconContext} from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll'

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);
    
    const changeNav = () => {
        if(window.scrollY >= 100){
            setScrollNav(true)
        } else{
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    });

    const toggleHome = () =>{
        scroll.scrollToTop();
    }

    return (
        <>
        <IconContext.Provider value = {{color: '#FF871C'}}>
            <Nav scrollNav = {scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick = {toggleHome}>
                    E’ZOZ
                    <NavSpan>restaurant</NavSpan>
                    </NavLogo>

                    <MobileIcon onClick = {toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about" smooth = {true} duration = {500} spy = {true} exact = 'true' offset = {-100}>ДОМОЙ</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="notabout" smooth = {true} duration = {500} spy = {true} exact = 'true' offset = {-100}>О НАС</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="location" smooth = {true} duration = {500} spy = {true} exact = 'true' offset = {-100}>АДРЕС</NavLinks>
                        </NavItem>
                        {/* <NavItem>
                            <NavLinks to="location" smooth = {true} duration = {500} spy = {true} exact = 'true' offset = {-100}>ФОТОГАЛЕРЕЯ</NavLinks>
                        </NavItem> */}
                        {/* <NavItem>
                            <NavLinks to="/signin" >КОНТАКТЫ</NavLinks>
                        </NavItem> */}
                        <NavBtn>
                            <NavBtnLink to="/gallery">ФОТОГАЛЕРЕЯ</NavBtnLink>
                        </NavBtn>
                        <NavBtn>
                            <NavBtnLink to="/signin">КОНТАКТЫ</NavBtnLink>
                        </NavBtn>
                    </NavMenu>
                    
                    
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
