import styled from 'styled-components'
import {BsFillTelephoneFill, BsTelephoneFill} from 'react-icons/bs'
import {FaFacebook, FaInstagram, FaLinkedin, FaShareAlt, FaTwitter, FaYoutube} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
export const SocialLabel = styled.label`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: .35s ease-in;
`

export const SocialIcons = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const Btn = styled.button`
    display: flex;
    height: 54px;
    width: 60px;
    background: #4CAF50;
    border-radius: 5px;
    cursor: pointer;
`

export const IconLink = styled.a`
    text-decoration: none;
    font-family: Open Sans Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #E37816;

    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
    &sm{
        visibility: hidden;
    }
    &active{
        visibility: visible;
        transition-delay: 0.3s;
    }
`

export const IconList = styled.ul`
    // display: flex;

    .open{
    -webkit-transition: height 0.7s;
    -moz-transition:height 0.7s;
    transition: height 0.7s;
    height: height 0.7s;
    }
    &toggle_button{
        width: 200px;
        height: 100px;
        background: #fff;
        border-radius: 50px;
        overflow: hidden;
        transition: all 0.2s ease;
    }
    &toggle_button active{
        height: 200px;
    }
    
`


const open = {
    main: "height 0.7s"
};

export const IconListWrap = styled.div`
    display: flex;
    width: 100px;
    height: 50px; 
    -webkit-transition: height 0.7s;
    -moz-transition: height 0.7s;
    transition: height 0.7s;
    position: fixed;
    // top: 50%;
    // left: 88%;
    top: 62%;
    left: 99%;
    transform: translate(-50%, -50%);
    z-index: 999;
    
`

export const Button_1 = styled.a`
    background: #000;
    color: #fff;
    width: 30px;
    cursor: pointer;
`

export const IconsL = styled.li`
    text-decoration: none;
    list-style: none;
    margin-bottom: 8px;
`

export const Icons = styled.ul`
  
`

export const Facebook = styled(FaFacebook)`
    width: 50px;
    height: 50px;
`

export const Instagram = styled(FaInstagram)`
    width: 50px;
    height: 50px;
`

export const Mail = styled(MdEmail)`
    width: 50px;
    height: 50px;
`

export const Telephone = styled(BsTelephoneFill)`
    width: 50px;
    height: 50px;
`

export const Image1 = styled.img`
    width: 50px;
    height: 50px;
    cursor: pointer;
`