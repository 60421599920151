import ImageOne from '../../images/image_19.jpg'
import ImageTwo from '../../images/image_20.jpg'
import ImageThree from '../../images/image_21.jpg'
import ImageFour from '../../images/image_22.jpg'
import ImageFive from '../../images/image_25.png'
import ImageSix from '../../images/image_26.png'
import ImageSeven from '../../images/image_27.png'
import ImageEight from '../../images/image_28.png'

export const ResInfoData = [
    {
        title: "Ресторан E'zoz — это 2 роскошных зала со всеми удобствами",
        price: 'Большой зал вмещает 450 человек;',
        path: '/homes',
        label: 'View Home',
        image: ImageEight,
        alt: 'Out'
    },
    {
        title: "Ресторан E'zoz — это 2 роскошных зала со всеми удобствами",
        price: 'Второй зал вмещает 120 человек.',
        path: '/homes',
        label: 'View Home',
        image: ImageThree,
        alt: 'Inside'
    },
    {
        title: 'Свадьбы',
        price: 'Свадьба и все что с ней связано великое событие в жизни каждого рода, каждой семьи. Узбекские свадьбы отличаются особым размахом и множеством традиционных ритуалов.',
        path: '/homes',
        label: 'View Home',
        image: ImageSeven,
        alt: 'Main Building'
    },
    {
        title: 'Юбилеи',
        price: 'Yubilei',
        path: '/homes',
        label: 'View Home',
        image: ImageSix,
        alt: 'Hall'
    },
    {
        title: 'Корпоративы',
        price: 'Korporativi',
        path: '/homes',
        label: 'View Home',
        image: ImageOne,
        alt: 'Hall'
    },
    {
        title: 'Семейные посиделки',
        price: 'Семейные посиделки',
        path: '/homes',
        label: 'View Home',
        image: ImageFive,
        alt: 'Hall'
    },
    {
        title: 'Дни рождения',
        price: 'В преддверии дня рождения, каждый испытывает тревогу и волнение. Ведь пока близкие люди раздумывают над темой подарка, имениннику нужно продумать, где весело и душевно отметить праздник',
        path: '/homes',
        label: 'View Home',
        image: ImageFour,
        alt: 'Hall'
    }
];