import React, {useState} from 'react'
import Gallery1 from '../components/Gallery'
import Navbar from '../components/SignNav'
import ScrollToTop from '../components/ScrollToTop'
import Sidebar from '../components/SignSideBar'
import Footer from '../components/Footer'
import {Helmet} from 'react-helmet'

const GalleryPage = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () =>{
        setIsOpen(!isOpen);
    }
    return (
        <>
            <Helmet>
                <title>Gallery</title>
                <meta
                    name="description"
                    content="Photo Gallery Ezoz | E'zoz | Эзоз" 
                />
            </Helmet>
            <ScrollToTop /> 
            <Sidebar isOpen = {isOpen} toggle = {toggle} />
            <Navbar toggle = {toggle} />
            <Gallery1/>
            <Footer/>
        </>
    )
}

export default GalleryPage;
