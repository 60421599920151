import React from 'react'
import {SidebarContainer, Icon, ClodeIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen = {isOpen} onClick = {toggle}>
            <Icon onClick = {toggle}>
                <ClodeIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {/* <SidebarLink to="/home" onClick = {toggle}>
                        ДОМОЙ
                    </SidebarLink> */}
                    <SidebarRoute to="/" onClick = {toggle}>
                    ДОМОЙ
                    </SidebarRoute>
                    {/* <SidebarLink to="notabout" onClick = {toggle}>
                        О НАС
                    </SidebarLink>
                    <SidebarLink to="location" onClick = {toggle}>
                        АДРЕС
                    </SidebarLink> */}
                    <SidebarRoute to="/gallery" onClick = {toggle}>
                    ФОТОГАЛЕРЕЯ
                    </SidebarRoute>
                    <SidebarRoute to="/signin" onClick = {toggle}>
                    КОНТАКТЫ
                    </SidebarRoute>
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to="/signin">Sign In</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
