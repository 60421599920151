import React, {useState} from 'react'
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import ResInfoSection from '../components/ResInfo';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Video from '../components/VideoSection';
import {SliderData} from '../components/InfoSection/InfoElements'
import Location from '../components/Location';
import Lloc from '../components/Lloc';
import Footer from '../components/Footer';
import StickyButton from '../components/StickyButton';
import {Helmet} from 'react-helmet';
import ResInfoTwo from '../components/ResInfoTwo';
import {ResInfoData} from '../components/ResInfoTwo/ResInfoData';
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () =>{
        setIsOpen(!isOpen);
    }
    console.log(ResInfoData);

    return (
        <>
            <Helmet>
                <title>Ezoz restaurant</title>
                <meta
                    name="description"
                    content="Добро пожаловать в ресторан Ezoz | Эзоз | E'zoz " 
                /> 
            </Helmet>
            <Sidebar isOpen = {isOpen} toggle = {toggle} />
            <Navbar toggle = {toggle} />
            <HeroSection />
            <Video/>
            <InfoSection/>
            <ResInfoTwo slides = {ResInfoData}/>
            <ResInfoSection />
            <Location/>    
            <Footer/>
            <StickyButton/>
        </>
    )
}

export default Home