import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api/api";

export const videoBg = createAsyncThunk("videoBg", async (__, thunkAPI) => {
  try {
    const response = await api.background();
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
export const video = createAsyncThunk("video", async (__, thunkAPI) => {
  try {
    const response = await api.video();
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const mainNumber = createAsyncThunk(
  "mainNumber",
  async (__, thunkAPI) => {
    try {
      const response = await api.mainNumber();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);

export const firstSlider = createAsyncThunk(
  "firstSlider",
  async (__, thunkAPI) => {
    try {
      const response = await api.firstSlider();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);
export const centralPicture = createAsyncThunk(
  "centralPicture",
  async (__, thunkAPI) => {
    try {
      const response = await api.centralPicture();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);
export const secondSlid = createAsyncThunk(
  "secondSlid",
  async (__, thunkAPI) => {
    try {
      const response = await api.secondSlider();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);

export const gallery = createAsyncThunk("gallery", async (__, thunkAPI) => {
  try {
    const response = await api.gallery();
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
export const number = createAsyncThunk("num", async (__, thunkAPI) => {
  try {
    const response = await api.number();
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
export const firstComment = createAsyncThunk(
  "firstComment",
  async (__, thunkAPI) => {
    try {
      const response = await api.firstComment();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);
export const secondComment = createAsyncThunk(
  "secondComment",
  async (__, thunkAPI) => {
    try {
      const response = await api.secondComment();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);

export const request = createAsyncThunk("request", async (req, thunkAPI) => {
  try {
    const response = await api.request(req);
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
const initialState = {
  videoBack: "",
  video: "",
  first_slider: "",
  centralPicture: "",
  gallery: "",
  second_slider: "",
  first_comment: "",
  second_comment: "",
  request: "",
  mainNumber: "",
  number: "",
};

export const apiSlice = createSlice({
  name: "api",
  initialState,
  extraReducers: {
    [videoBg.fulfilled]: (state, action) => {
      console.log(action)
      state.videoBack = action.payload;
    },
    [video.fulfilled]: (state, action) => {
      state.video = action.payload;
    },
    [firstSlider.fulfilled]: (state, action) => {
      state.first_slider = action.payload;
    },
    [centralPicture.fulfilled]: (state, action) => {
      state.centralPicture = action.payload;
    },
    [secondSlid.fulfilled]: (state, action) => {
      state.second_slider = action.payload;
    },
    [gallery.fulfilled]: (state, action) => {
      console.log(action)
      state.gallery = action.payload;
    },
    [firstComment.fulfilled]: (state, action) => {
      state.first_comment = action.payload;
    },
    [secondComment.fulfilled]: (state, action) => {
      state.second_comment = action.payload;
    },
    [request.fulfilled]: (state, action) => {
      state.request = action.payload;
    },
    [mainNumber.fulfilled]: (state, action) => {
      state.mainNumber = action.payload;
    },
    [number.fulfilled]: (state, action) => {
      state.number = action.payload;
    },
  },
});

export default apiSlice.reducer;
