import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'

export const MainContainer = styled.div`
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 150px;
    overflow-x: hidden;
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
`

export const MainWrapper = styled.div`
    max-width: 1140px;
    min-height: 500px;
    margin: auto;
    display: grid;
    grid-template-columns: 5fr 4fr;
    align-items: center;
    grid-gap: 29px;


    

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
    }
`

export const SliderContainer = styled.div`
    max-width: 640px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // img: require('../../images/image_2.png').default;

    @media screen and (max-width: 320px){
        max-width: 229px !important;
        margin: 0 auto;
    }
    @media screen and (max-width: 440px){
        max-width: 200px !important;
        margin: 0 auto;
    }
    @media screen and (max-width: 550px){
        max-width: 300px !important;
        margin: 0 auto;
    }
    @media screen and (max-width: 576px){
        max-width: 332px;
        margin: 0 auto;
    }
    @media screen and (max-width: 640px){
        max-width: 500px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    @media screen and (max-width: 1000px){
        // max-width: 400px;
        margin: 0 auto;
    }
    @media screen and (max-width: 1200px){
        // max-width: 535px;
        margin: 0 auto;
    }
    
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 576px){
        align-items: center;
    }
    @media screen and (max-width: 992px){
        align-items: center;
    }
`

export const InfoH1 = styled.h1`
    font-family: Caviar Dreams;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.1em;
    color: #E37816;
    margin-bottom: 40px;
    text-transform: uppercase;
    @media screen and (max-width: 576px){
        text-align: center !important;
        font-size: 27px !important;
        margin-bottom: 23px !important;
    }
    @media screen and (max-width: 400px){
        text-align: center;
        font-size: 20px !important;
        line-height: 40px !important;

    }
    @media screen and (max-width: 992px){
        text-align: center;
        font-size: 30px;
        line-height: 40px;

    }
`

export const InfoP = styled.p`
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    margin-bottom: 40px;
    @media screen and (max-width: 576px){
        text-align: center;
    }
    @media screen and (max-width: 992px){
        text-align: center;
    }
`

export const InfoH2 = styled(LinkS)`
    width: 139px;
    height: 30px;
    font-family: Open Sans Condensed;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    border-bottom: 1px solid #E37816;
    padding: 6px 0;
    position: relative;
    cursor: pointer;
    &:after {
        content: '';
        border-bottom: 1px solid #E37816;
        width: 100%;
        position: absolute;
        bottom: -6px;
        left: 0;
        }
`
