import styled from 'styled-components'
import img from '../../images/svg_1.svg'

export const LocationContainer = styled.div`
    // padding-right: 15px;
    // padding-left: 15px;
    // margin-right: auto;
    // margin-left: auto;
    margin-top: 90px;
    margin-bottom: 85px;
    overflow-x: hidden;
    // @media (min-width: 768px) {
    //     width: 750px;
    // }
    // @media (min-width: 992px) {
    //     width: 970px;
    // }
    // @media (min-width: 1200px) {
    //     width: 1170px;
    // }
    // @media screen and (max-width: 440px){
    //     margin-bottom: 50px;
    // }
    // margin-top: 120px;
    // max-width: 1170px;
`
export const LocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const LocationP = styled.p`
    font-family: Miniver;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.76px;
    color: #FF871C;
    margin-bottom: 18px;
    @media screen and (max-width: 440px){
        font-size: 14px;
    }
`

export const LocationH1Wrapper = styled.div`
    position: relative;
    margin-bottom: 85px;
    &:before{
        content: {img};
        // background-image: require('../../images/svg_1.svg').default;
        background-size: 40px 40px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
    }
    &:after{
        content: '';
        img: require('../../images/svg_1.svg').default;
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
    }
`

export const LocationH1 = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 9.43px;
    text-transform: uppercase;
    color: #FF871C;
    position: relative;
    margin-bottom: 85px;
    
    &:before{
        content: '';
        width: 41px;
        height: 9px;
        background-image: url(${img});
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        left: -18%;
        top: 16px;
    }
    &:after{
        content: '';
        width: 41px;
        height: 9px;
        background-image: url(${img});
        position: absolute;
        bottom: 0;
        right: -16%;
        top: 16px;
    }
    @media screen and (max-width: 440px){
        font-size: 18px;
    }
`

export const LocationFrame = styled.iframe`
    display: flex;
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
    overflow: scroll;
    // @media screen and (max-width: 576px){
    //     height: 560px;
    // }
`

export const FrameWrap = styled.div`
    // text-align: center;
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
`